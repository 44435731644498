.center {
  display: grid;
  place-items: center;
  height: 100vh;
}
.spinCenter {
  display: grid;
  place-items: center;
  height: 75vh;
}
.white {
  color: #ffffff;
}
.spinCenter2 {
  display: grid;
  place-items: center;
  height: 80px;
}
.tableHead {
  background-color: #ff9800c9;
}

.chipWarning {
  border-color: #ff9800 !important;
  color: #ff9800 !important;
  font-weight: 500;
}
.chipSuccess {
  border-color: #4caf50 !important;
  color: #4caf50 !important;
  font-weight: 500;
}
.chipDanger {
  border-color: red !important;
  color: red !important;
  font-weight: 500;
}

.success {
  color: #4caf50;
}
.warning {
  color: #ff9800;
}
.danger {
  color: red;
}
.download {
  color: #ff0066;
}
.download:hover {
  color: #446bfe;
}

.titleText {
  min-width: 50px;
  font-weight: 500;
  padding-right: 10px;
  font-size: 18px;
}
.titleValueText {
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
}
@media only screen and (max-width: 400px) {
  .titleText {
    font-size: 16px;
  }
  .titleValueText {
    font-size: 14px;
  }
}
